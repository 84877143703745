<template>
  <div>
    <div class="share-network-list"></div>

    <div v-if="loadingRecoverSponsorCode">
      <div class="ml-2">
        <clip-loader
          :loading="loadingRecoverSponsorCode"
          color="primary"
        ></clip-loader>
      </div>
    </div>
    <div v-else class="m-4">
      <div class="pt-5 pb-5 flex justify-center">
        <h1>Statistiques</h1>
      </div>

      <div class="p-2">
        <vs-collapse>
          <vs-collapse-item class="aps-benin-collapse-item">
            <div slot="header">
              <div class="aps-benin-collapse-filter">Filtrage</div>
            </div>
            <div class="p-2">
              <vs-row vs-w="12" vs-align="center">
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">Du</span>
                  <div
                    class="inputx w-full flex justify-around"
                    style="align-items: center;"
                  >
                    <vs-input
                      v-model="startDate"
                      type="date"
                      class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">Au </span>
                  <div
                    class="inputx w-full flex justify-around "
                    style="align-items: center;"
                  >
                    <vs-input
                      type="date"
                      v-model="endDate"
                      :min="startDate"
                      class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4 mb-2 mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <multiselect
                      v-model="productName"
                      :options="allProduct"
                      :close-on-select="true"
                      placeholder="--Choisir un produit--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" vs-align="center" vs-justify="center">
                <vs-col
                  class="pt-4 pb-2"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <vs-button @click="resetFilter">
                    Réinitialiser
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
      <div v-if="loadingStatCard">
        <div class="ml-2">
          <clip-loader :loading="loadingStatCard" color="primary"></clip-loader>
        </div>
      </div>
      <div v-else class=" pb-5 ">
        <vs-row vs-w="12" vs-align="center">
          <vs-col
            class="p-2"
            vs-lg="4"
            vs-md="4"
            vs-sm="6"
            :key="stat.label"
            v-for="stat in statsList"
          >
            <statistics-card-line
              hideChart
              icon="BarChart2Icon"
              icon-right
              :statistic="stat.value"
              :statisticTitle="stat.label"
            />
          </vs-col>
        </vs-row>
      </div>

      <div class="pt-5 pb-5 flex justify-center">
        <h1>Equipe</h1>
      </div>

      <div>
        <vx-card>
          <div slot="no-body" class="p-2">
            <div class="p-2">
              <div v-if="loadingTable">
                <div class="ml-2">
                  <clip-loader
                    :loading="loadingTable"
                    color="primary"
                  ></clip-loader>
                </div>
              </div>
              <div v-else>
                <div v-if="userTeam.length !== 0">
                  <vs-table
                    @dblSelection="teamSelectedDouble"
                    :data="userTeam"
                    v-model="teamSelected"
                    search
                    stripe
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="firstname">Nom</vs-th>
                      <vs-th sort-key="lastname">Prénom</vs-th>
                      <vs-th sort-key="forSociety">Société</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].person.firstname">{{
                          data[indextr].person.firstname
                        }}</vs-td>
                        <vs-td :data="data[indextr].person.lastname">{{
                          data[indextr].person.lastname
                        }}</vs-td>
                        <vs-td :data="data[indextr].person.forSociety">{{
                          data[indextr].person.forSociety
                        }}</vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <div>
                              <vx-tooltip color="primary" text="Aperçu">
                                <div
                                  class="cursor-pointer"
                                  @click="checkViewMemberStats(data[indextr])"
                                >
                                  <feather-icon
                                    icon="EyeIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>

      <vs-popup
        class="holamundo"
        :title="
          `Aperçu Statistique ` +
            crrUserData.person.firstname +
            ' ' +
            crrUserData.person.lastname
        "
        fullscreen
        :active.sync="popupViewMemberStats"
      >
        <div v-if="loadingMenberStats">
          <clip-loader
            :loading="loadingMenberStats"
            color="primary"
          ></clip-loader>
        </div>
        <div v-else class="aps-benin-description">
          <div class="p-2">
            <vs-collapse>
              <vs-collapse-item class="aps-benin-collapse-item">
                <div slot="header">
                  <div class="aps-benin-collapse-filter">Filtrage</div>
                </div>
                <div class="p-2">
                  <vs-row vs-w="12" vs-align="center">
                    <vs-col
                      class="pr-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="4"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Du</span>
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center;"
                      >
                        <vs-input
                          v-model="startDateForMember"
                          type="date"
                          class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="4"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Au </span>
                      <div
                        class="inputx w-full flex justify-around "
                        style="align-items: center;"
                      >
                        <vs-input
                          type="date"
                          v-model="endDateForMember"
                          :min="startDateForMember"
                          class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="4"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <multiselect
                          v-model="productForMember"
                          :options="allProduct"
                          :close-on-select="true"
                          placeholder="--Choisir un produit--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col
                      class="pt-4 pb-2"
                      vs-type="flex"
                      vs-align="center"
                      vs-justify="center"
                      vs-lg="4"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <vs-button @click="resetFilterForMember">
                        Réinitialiser
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
          <div v-if="loadingStatCardMe">
            <div class="ml-2">
              <clip-loader
                :loading="loadingStatCardMe"
                color="primary"
              ></clip-loader>
            </div>
          </div>
          <div v-else class=" pb-5 ">
            <vs-row vs-w="12" vs-align="center">
              <vs-col
                class="p-2"
                vs-lg="4"
                vs-md="4"
                vs-sm="6"
                :key="stat.label"
                v-for="stat in statsListForMember"
              >
                <statistics-card-line
                  hideChart
                  icon="BarChart2Icon"
                  icon-right
                  :statistic="stat.value"
                  :statisticTitle="stat.label"
                />
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Multiselect from "vue-multiselect";
import global from "@/config/global";
import role from "@/config/role";

export default {
  data() {
    return {
      loadingRecoverSponsorCode: false,
      loadingStatCard: false,
      loadingStatCardMe: false,
      error: false,
      isResetFilter: false,
      statsList: [],
      productList: [],
      productName: "",
      startDate: "",
      endDate: "",
      userTeam: [],

      loadingTable: false,

      // members stats
      isResetFilterForMember: false,
      statsListForMember: [],
      productListForMember: [],
      productForMember: "",
      startDateForMember: "",
      endDateForMember: "",
      crrUserData: {
        person: {
          firstname: "",
          lastname: ""
        }
      },
      // members stats

      global: {},
      teamSelected: "",
      popupViewMemberStats: false,
      loadingMenberStats: false
    };
  },
  components: {
    StatisticsCardLine,
    ClipLoader,
    Multiselect
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allProduct"])
  },
  created() {
    if (!this.checkUserRole("stats_team").all)
      this.$router.push({ name: "Home" });
    this.global = global;
    this.role = role;
  },
  mounted() {
    if (
      !(
        this.user.type === "broker_inspector" ||
        this.user.type === "broker_leader"
      )
    ) {
      this.$router.push({ name: "Home" });
    }
    this.getStats({
      userId: this.user.id,
      userCode: this.user.userCode,
      startDate: this.startDate,
      endDate: this.endDate,
      productName: this.productName.id ? this.productName.id : "",
      by: "team"
    });
    this.getIntermediaryTeam({
      userId: this.user.id,
      userCode: this.user.userCode,
      startDate: this.startDate,
      endDate: this.endDate,
      productName: this.productName.id ? this.productName.id : ""
    });
  },
  methods: {
    ...mapActions("general", ["applyParrainStats", "applyMemberStats"]),
    ...mapActions("users", ["applyGetUserSys"]),
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },

    async getIntermediaryTeam(data) {
      this.loadingTable = true;
      let users = { ...data };
      try {
        let team = await this.applyGetUserSys(users);
        this.userTeam = team.data.intermediaries;
        console.log("les users:", team);

        this.loadingTable = false;
      } catch (err) {
        this.loadingTable = false;

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "quittanceNotFound":
                  this.errorMsg = "La quittance n'existe pas";
                  this.acceptAlert("danger", "Quittance", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Quittance",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async getStats(data) {
      let userStat = { ...data };
      this.loadingStatCard = true;
      try {
        let stats = await this.applyParrainStats(userStat);
        this.statsList = stats.stats;
        this.productList = stats.products;
        this.loadingStatCard = false;
        this.isResetFilter = false;
        this.error = false;
      } catch (err) {
        this.isResetFilter = false;
        this.loadingStatCard = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              "danger",
              "STATISTIQUES",
              "Une erreur s'est produite"
            );
          }
        }
      }
    },

    async getMemberStats(data) {
      let userStat = { ...data };

      this.loadingStatCardMe = true;
      try {
        let stats = await this.applyParrainStats(userStat);
        this.statsListForMember = stats.stats;
        this.productListForMember = stats.products;
        this.loadingStatCardMe = false;
        this.isResetFilterForMember = false;
        this.error = false;
      } catch (err) {
        this.isResetFilterForMember = false;
        this.loadingStatCardMe = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              "danger",
              "STATISTIQUES",
              "Une erreur s'est produite"
            );
          }
        }
      }
    },

    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.productName = "";
      this.getStats({
        userId: this.user.id,
        userCode: this.user.userCode,
        startDate: this.startDate,
        endDate: this.endDate,
        productName: this.productName.id ? this.productName.id : "",
        by: "team"
      });
    },

    resetFilterForMember() {
      this.isResetFilterForMember = true;
      this.startDateForMember = "";
      this.endDateForMember = "";
      this.productForMember = "";
      this.getMemberStats({
        userId: this.crrUserData.id,
        userCode: this.crrUserData.userCode,
        startDate: this.startDateForMember,
        endDate: this.endDateForMember,
        productName: this.productForMember.id ? this.productForMember.id : "",
        by: "me"
      });
    },

    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },

    teamSelectedDouble(item) {
      this.checkViewMemberStats(item);
    },

    checkViewMemberStats(data) {
      this.crrUserData = { ...data };
      //vider le champs
      this.isResetFilterForMember = true;
      this.startDateForMember = "";
      this.endDateForMember = "";
      this.productForMember = "";
      this.getMemberStats({
        userId: this.crrUserData.id,
        userCode: this.crrUserData.userCode,
        startDate: this.startDateForMember,
        endDate: this.endDateForMember,
        productName: this.productForMember.id ? this.productForMember.id : "",
        by: "me"
      });
      this.popupViewMemberStats = true;
    }
  },
  watch: {
    productName() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.productName.id ? this.productName.id : "",
          by: "team"
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.productName.id ? this.productName.id : "",
          by: "team"
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.productName.id ? this.productName.id : "",
          by: "team"
        });
      }
    },
    productForMember() {
      if (!this.isResetFilterForMember) {
        this.getMemberStats({
          userId: this.crrUserData.id,
          userCode: this.crrUserData.userCode,
          startDate: this.startDateForMember,
          endDate: this.endDateForMember,
          productName: this.productForMember.id ? this.productForMember.id : "",
          by: "me"
        });
      }
    },
    startDateForMember() {
      if (!this.isResetFilterForMember) {
        this.getMemberStats({
          userId: this.crrUserData.id,
          userCode: this.crrUserData.userCode,
          startDate: this.startDateForMember,
          endDate: this.endDateForMember,
          productName: this.productForMember.id ? this.productForMember.id : "",
          by: "me"
        });
      }
    },
    endDateForMember() {
      if (!this.isResetFilterForMember) {
        this.getMemberStats({
          userId: this.crrUserData.id,
          userCode: this.crrUserData.userCode,
          startDate: this.startDateForMember,
          endDate: this.endDateForMember,
          productName: this.productForMember.id ? this.productForMember.id : "",
          by: "me"
        });
      }
    }
  }
};
</script>

<style>
.aps-benin-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
.aps-benin-title-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vs-con-input {
  width: 100%;
}

.aps-benin-description {
  padding: 0 12vw;
}
</style>
